import * as React from 'react';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { useHistory } from 'react-router-dom';

import Card from '@common/Card';
import MainButton from '@common/MainButton';
import SecondaryButton from '@common/SecondaryButton';
import Spinner from '@common/Spinner';

import useAuth from '@auth/useAuth';
import useAsyncAction from '@common/useAsyncAction';
import { getAxios } from '@common/utilities';

import s from './QuestionnaireRecap.module.css';

export default function QuestionnaireRecap({ answers, restart }) {
  const history = useHistory();
  const saveAnswersAsync = useAsyncAction();

  const { mutate } = useAuth({ fireOnMount: false });

  React.useEffect(() => {
    const res = saveAnswersAsync.data;
    if (res && res.status === 200) {
      history.push('/styles');
    }
  }, [saveAnswersAsync.data]);

  function saveAnswers() {
    const answersToSend = answers.map(({ recap, ...rest }) => rest);

    const axios = getAxios();
    const promise = axios
      .post(`/users/me/profiling`, {
        answers: answersToSend
      })
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          mutate();
        }

        return res;
      });

    saveAnswersAsync.run(promise);
  }

  const isLoading = saveAnswersAsync.isLoading;

  return (
    <div className={s.Wrapper}>
      <h1 className={s.Title}>
        <Trans id="Questionnaire.Recap.profile" />
      </h1>

      <Card className={s.Card}>
        <div>
          <mark className="highlight">
            <Trans id="Questionnaire.Recap.today" />
          </mark>
        </div>

        <ul className={s.List}>
          {answers
            .filter(answ => answ.source === 'profile')
            .map((answer, idx) => (
              <li key={idx}>{answer.recap}</li>
            ))}
        </ul>
      </Card>

      <div className={s.Bottom}>
        <div className={s.BottomTitle}>
          <Trans id="Questionnaire.Recap.confirm" />
        </div>

        <div className={s.Button}>
          <MainButton onClick={saveAnswers}>
            {isLoading ? (
              <Spinner />
            ) : (
              /*i18n*/ i18n._('Questionnaire.Recap.confirm.yes')
            )}
          </MainButton>
        </div>
        <SecondaryButton
          onClick={() => {
            if (!isLoading) {
              restart();
            }
          }}
        >
          <Trans id="Questionnaire.Recap.no" />
        </SecondaryButton>
      </div>
    </div>
  );
}
